@layer reset, antd;
@import 'antd/dist/reset.css' layer(reset);

@layer tailwind-base, antd;

@layer tailwind-base {
  @tailwind base;
}

@tailwind components;
@tailwind utilities;
body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.testaroo {
  margin: 0;
}

.ant-modal-header {
  border-bottom: 0 !important;
}

.ant-modal-footer {
  border-top: 0 !important;
}
